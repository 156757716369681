import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { RWebShare } from 'react-web-share';
import ReadmoreButton from '../ReadmoreButton/ReadmoreButton';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { formatMoney } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import { FaStar } from 'react-icons/fa';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ProviderListingCard,
  PaginationLinks,
  Map,
  SectionFooterTop,
  ModalInMobile,
  ResponsiveImage,
  IconSpinner,
  AvatarMedium,
  UserNav,
  AvatarLarge,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '..';
import { MdEmail, MdContentCopy } from 'react-icons/md';
import { FaFacebook, FaWhatsapp, FaTwitter, FaSnapchat, FaLinkedin } from 'react-icons/fa';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import pfimg1 from '../../assets/background-1440.jpg';
import pfimg2 from '../../assets/background-14401.jpg';
import Slider from 'react-slick';
import { RiShareForwardLine } from 'react-icons/ri';
import ImageGallery from 'react-image-gallery';
import {
  AiFillHeart,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineHeart,
} from 'react-icons/ai';
import providerimg from '../../assets/topRead2.jpg';
import Wave from '../../components/Wave/Wave';
import { apiBaseUrl } from '../../util/api';
import moment from 'moment';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
// import 'react-image-gallery/styles/css/image-gallery.css';
import { withViewport } from '../../util/contextHelpers';
import AspectRatioWrapper from '../../components/AspectRatioWrapper/AspectRatioWrapper';
import axios from 'axios';
import classNames from 'classnames';
import { BiUpArrowAlt } from 'react-icons/bi';
import { initiateZeroOrder } from '../CheckoutPage/CheckoutPage.duck';
import { placesToVisitActivityTypeOptions } from '../../assets/activityTypeOptions';
import StarRating from '../../components/StarRating/StarRating';
import { formatListingData } from '../../util/formattedListingData';
import {
  updateProfile,
  updateProfileWithoutSuccessTrigger,
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import ShareButton from '../../components/ShareButton/ShareButton';
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, LatLng } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};
export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      setFavouriteLoading: false,
      isFavourite: null,
      copiedNotification: false,
      checkoutLoading: false,
      openShareModal: false,
    };
    this.isFavouriteCalledRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    this.setState(state => ({ ...state, checkoutLoading: true }));
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      onInitiateZeroOrder,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const zeroSuccessCallback = id => {
      const routes = routeConfiguration();
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
        id,
      });
      this.setState(state => ({ ...state, checkoutLoading: false }));
      history.push(orderDetailsPath);
    };
    const { startDate, endDate } = listing.attributes.publicData;
    const { bookingStartTime, bookingEndTime, emgContact, ...restOfValues } = values;
    const bookingStart = new Date(startDate);
    const bookingEnd = new Date(endDate);

    const bookingData = {
      startDate,
      endDate,
      stockReservationQuantity: values.child?.length,
      quantity: values.child?.length,
      seats: values.child?.length,
      child: values.child && values.child,
      emgContact,
      customerDetails: {
        customerName: `${this.props.currentUser.attributes.profile.firstName} ${this.props.currentUser.attributes.profile.lastName}`,
        customerEmail: this.props.currentUser.attributes.email,
        customerPhnNo: this.props.currentUser.attributes?.profile?.protectedData?.phnNo,
      },
      trialKidsCount: values.child?.filter(c => c.isTrial)?.length,
      ...restOfValues,
    };
    const initialValues = {
      listing,
      listingId: listing.id.uuid,
      bookingData,
      // bookingDates: {
      //   bookingStart,
      //   bookingEnd,
      // },
      stockReservationQuantity: bookingData.seats,
      protectedData: {
        startDate,
        endDate,
        childDetails: values.child && values.child,
        trialKidsCount: values.child?.filter(c => c.isTrial)?.length,
        emgContact,
        customerDetails: {
          customerName: `${this.props.currentUser.attributes.profile.firstName} ${this.props.currentUser.attributes.profile.lastName}`,
          customerEmail: this.props.currentUser.attributes.email,
          customerPhnNo: this.props.currentUser.attributes?.profile?.protectedData?.phnNo,
        },
      },
      confirmPaymentError: null,
    };
    const listingPrice = listing.attributes.price.amount;
    const listingCategory = listing.attributes.publicData.categories[0];
    if (listingPrice === 0 && listingCategory === 'workshops') {
      const orderParams = {
        ...initialValues,
        // ...initialValues.bookingDates,
        seats: bookingData.seats,
        quantity: bookingData.seats,
      };
      return onInitiateZeroOrder({ ...orderParams, callback: zeroSuccessCallback });
    } else {
      const saveToSessionStorage = !this.props.currentUser;

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

      callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();
      this.setState(state => ({ ...state, checkoutLoading: false }));
      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
          {}
        )
      );
    }
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  isCurrentListingFavourite = async (userId, listingId) => {
    const currentUserFavourites = await axios.post(`${apiBaseUrl()}/api/is-listing-favourite`, {
      userId,
      listingId,
    });
    return currentUserFavourites;
  };
  toggleFavouritesButton = async (userId, listingId) => {
    const isFavouriteListing = await axios.post(`${apiBaseUrl()}/api/toggle-favourites`, {
      userId,
      listingId,
    });
    getCurrentUserFavourites(currentUser.id.uuid).then(res => {
      setCurrentUserFavourites(res.data.listingIds);
    });
    return isFavouriteListing;
  };
  componentDidMount() {
    this.isFavouriteCalledRef.current = false;
  }
  componentDidUpdate() {
    const { currentUser, params: rawParams } = this.props;
    const listingId = new UUID(rawParams.id);
    if (
      currentUser?.id?.uuid &&
      this.state.isFavourite === null &&
      this.isFavouriteCalledRef.current === false
    ) {
      this.isFavouriteCalledRef.current = true;
      this.isCurrentListingFavourite(currentUser.id.uuid, listingId.uuid).then(res => {
        this.setState(prevState => ({ ...prevState, isFavourite: res.data }));
      });
    }
  }
  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      // onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      viewport,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      authorListings,
      authorListingsPagination,
      history,
      onUpdateProfile,
    } = this.props;
    const { width } = viewport;
    const countyOption = findOptionsForSelectFilter('county', filterConfig);
    // const shouldShowCity = location?.state?.from?.includes('pub_county');
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    // const listingTab = isDraftVariant ? 'photos' : 'categories';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    const {
      county,
      city,
      startDate,
      endDate,
      whatToBring,
      categories,
      age,
      startTime,
      allAges,
      day,
      endTime,
      priceType,
      trialOption,
      depositOption,
      siblingDiscount,
      wheelChair,
      specialNeeds,
      lat,
      lng,
      address,
      depositOptionAmount,
      childOne,
      childTwo,
      trialFee,
      additionalInfo,
      openTill,
      noOfKids,
      noOfDays,
      additionalPricingInfo,
      noOfClasses,
      closingTime,
      activityType,
      trialMsg,
      classType,
    } = publicData;
    const activityTypesObj = activityType?.map(a =>
      placesToVisitActivityTypeOptions.find(o => o.value === a)
    );
    const origin = lat && lng && new LatLng(lat, lng);
    const bounds = lat && lng && locationBounds(new LatLng(lat, lng), 10);
    const urlShr = typeof window !== 'undefined' ? window.location : null;
    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const isCurrentUserProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const { listingString } = formatListingData(currentListing);
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { listingData: listingString, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const images = currentListing?.images
      ? currentListing?.images.map(i => ({
          original: i.attributes?.variants?.['scaled-large']?.url,
          thumbnail: i.attributes?.variants?.['scaled-small']?.url,
          originalClass: css.mainimg,
          thumbnailClass: css.thumbnail,
        }))
      : [];

    const paginationLinks =
      authorListingsPagination && authorListingsPagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ListingPage"
          // pageSearchParams={location.search}
          pagination={authorListingsPagination}
          pagePathParams={{ id: currentListing.id.uuid, slug: currentListing.attributes.title }}
        />
      ) : null;
    const openCloseInfo =
      categories[0] === 'afterSchoolClub' || categories[0] === 'breakfastClub' ? (
        <div className={css.opentmngs}>
          <label>Opening Times</label>{' '}
          <span className={css.opnspn}>
            <span>OPEN:</span> {publicData?.open}
          </span>
          <span className={css.opnspn}>
            <span>CLOSED:</span> {publicData?.closed}
          </span>
        </div>
      ) : null;

    const birthdayDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>
        <li>
          <span>Time:</span> {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
          {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute} hours
        </li>
        <li>
          <span>Min - Max: </span> {noOfKids?.minNo} - {noOfKids?.maxNo}
        </li>

        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
          {startDate && endDate && `(${moment(endDate)?.diff(moment(startDate), 'days')} days)`}{' '}
        </li>
      </ul>
    );
    const afterSchlDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>
        <li>
          <span>Opens:</span> {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
          {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute}
        </li>
        <li>
          <span>Closes:</span>{' '}
          {closingTime?.hour < 10 ? `0${closingTime?.hour}` : closingTime?.hour}.
          {closingTime?.minute < 10 ? `0${closingTime?.minute}` : closingTime?.minute}
        </li>
        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
          {startDate && endDate && `(${moment(endDate)?.diff(moment(startDate), 'days')} days)`}{' '}
        </li>
      </ul>
    );
    const schoolTourDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>
        <li>
          <span>Length:</span> {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
          {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute} hours
        </li>
        <li>
          <span>Min - Max: </span> {noOfKids?.minNo} - {noOfKids?.maxNo}
        </li>

        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? priceType === 'totalPrice'
              ? 'Total Price'
              : `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
          {startDate && endDate && `(${moment(endDate)?.diff(moment(startDate), 'days')} days)`}{' '}
        </li>
      </ul>
    );
    const classesDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Date:</span> {moment(startDate).format('MMM Do')} -{' '}
          {moment(endDate).format('MMM Do[,] YYYY')}
        </li>
        <li>
          <span>Time:</span> {startTime?.hour < 10 ? `0${startTime?.hour}` : startTime?.hour}.
          {startTime?.minute < 10 ? `0${startTime?.minute}` : startTime?.minute} –{' '}
          {endTime?.hour < 10 ? `0${endTime?.hour}` : endTime?.hour}.
          {endTime?.minute < 10 ? `0${endTime?.minute}` : endTime?.minute}{' '}
        </li>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>

        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
          {noOfClasses
            ? `(${noOfClasses} ${
                noOfClasses > 1 ? classType?.label || 'classes' : classType?.singular || 'class'
              })`
            : null}
        </li>
      </ul>
    );
    const campDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Date:</span>{' '}
          {`${moment(publicData?.startDate).format('MMM Do')} – ${moment(
            publicData?.endDate
          ).format('MMM Do, YYYY')}`}
        </li>
        <li>
          <span>Time:</span> {startTime?.hour < 10 ? `0${startTime?.hour}` : startTime?.hour}.
          {startTime?.minute < 10 ? `0${startTime?.minute}` : startTime?.minute} –{' '}
          {endTime?.hour < 10 ? `0${endTime?.hour}` : endTime?.hour}.
          {endTime?.minute < 10 ? `0${endTime?.minute}` : endTime?.minute}{' '}
        </li>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>

        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {noOfDays ? `(${noOfDays} ${noOfDays > 1 ? 'days' : 'day'})` : null}
        </li>
      </ul>
    );
    const workshopDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Date:</span> {moment(startDate).format('MMM Do[,] YYYY')}
        </li>
        <li>
          <span>Time:</span> {startTime?.hour < 10 ? `0${startTime?.hour}` : startTime?.hour}.
          {startTime?.minute < 10 ? `0${startTime?.minute}` : startTime?.minute} –{' '}
          {endTime?.hour < 10 ? `0${endTime?.hour}` : endTime?.hour}.
          {endTime?.minute < 10 ? `0${endTime?.minute}` : endTime?.minute}{' '}
        </li>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>

        <li>
          <span>Cost:</span> {price?.amount === 0 ? 'Free' : formattedPrice} (1 day)
        </li>
      </ul>
    );
    const inSchoolProgrammesDetails = (
      <ul className={css.listingul}>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>
        <li>
          <span>Time:</span> {openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}.
          {openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute} hours
        </li>
        <li>
          <span>Min - Max: </span> {noOfKids?.minNo} - {noOfKids?.maxNo}
        </li>
        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
        </li>
      </ul>
    );
    const placeVisitDetails = (
      <ul className={`${css.ptvul} ${css.listingul}`}>
        <li>
          <span>Age:</span> {allAges ? 'All ages' : age[0] - age[age.length - 1]}
        </li>
        <li>
          <span>Categories:</span>{' '}
          {activityTypesObj &&
            categories[0] === 'placesToVisit' &&
            activityTypesObj.reduce(
              (pre, curr, idx) =>
                `${pre}${
                  idx === 0
                    ? ` ${curr.label}`
                    : idx === activityTypesObj?.length - 1
                    ? ` & ${curr.label}`
                    : `, ${curr.label}`
                }`,
              ''
            )}
        </li>
        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
        </li>
      </ul>
    );
    const clubDetails = (
      <ul className={`${css.ptvul} ${css.listingul}`}>
        <li>
          <span>Age:</span> {age[0]} - {age[age.length - 1]}
        </li>
        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
        </li>
      </ul>
    );
    const eventDetails = (
      <ul className={`${css.ptvul} ${css.listingul}`}>
        <li>
          <span>Age:</span> {allAges ? 'All ages' : `${age[0]} - ${age[age.length - 1]}`}
        </li>
        <li>
          <span>Date:</span> {moment(startDate).format('MMM Do[,] YYYY')}{' '}
          {day ? `(${day?.length} ${day?.length > 1 ? 'days' : 'day'})` : null}
        </li>
        {day?.map((d, i) => (
          <li key={i}>
            <span>Time (day {i + 1}): </span>{' '}
            {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
            {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
            {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
            {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
          </li>
        ))}
        {city || county ? (
          <li>
            <span>Location:</span> {city ? city : county ? county?.toUpperCase() : ''}
          </li>
        ) : null}
        <li>
          <span>Cost:</span> {formattedPrice}{' '}
          {priceType
            ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
            : 'per child'}{' '}
        </li>
      </ul>
    );
    const listingInfos = (
      <div className={css.secwrow}>
        <div className={css.titlesec}>
          <div>
            <span>{title}</span>
            <span>
              <span>{currentAuthor?.attributes.profile.publicData?.bname}</span>-
              <span>
                {currentListing?.attributes?.publicData?.activityMode === 'online'
                  ? 'ONLINE'
                  : city
                  ? city?.toUpperCase()
                  : county?.toUpperCase()}
              </span>
            </span>
          </div>
          <span>
            {currentListing?.attributes?.publicData?.activityMode === 'online'
              ? 'From our screen to yours, an online adventure awaits!'
              : address}
          </span>
        </div>
        {categories[0] === 'birthdayParty' ? (
          birthdayDetails
        ) : categories[0] === 'afterSchoolClub' || categories[0] === 'breakfastClub' ? (
          afterSchlDetails
        ) : categories[0] === 'classes' ? (
          classesDetails
        ) : categories[0] === 'schoolTours' ? (
          schoolTourDetails
        ) : categories[0] === 'workshops' ? (
          workshopDetails
        ) : categories[0] === 'camps' ? (
          campDetails
        ) : categories[0] === 'inSchoolProgrammes' ? (
          inSchoolProgrammesDetails
        ) : categories[0] === 'placesToVisit' ? (
          placeVisitDetails
        ) : categories[0] === 'club' ? (
          clubDetails
        ) : categories[0] === 'events' ? (
          eventDetails
        ) : (
          <ul className={css.listingul}>
            <li>
              <span>Starts:</span> {moment(startDate).format('MMM Do[,] YYYY')}
            </li>
            <li>
              <span>Time:</span> {startTime?.hour < 10 ? `0${startTime?.hour}` : startTime?.hour}.
              {startTime?.minute < 10 ? `0${startTime?.minute}` : startTime?.minute} –{' '}
              {endTime?.hour < 10 ? `0${endTime?.hour}` : endTime?.hour}.
              {endTime?.minute < 10 ? `0${endTime?.minute}` : endTime?.minute}{' '}
            </li>
            <li>
              <span>Age:</span> {age[0]} - {age[age.length - 1]}
            </li>
            <li>
              <span>Cost:</span> {formattedPrice}{' '}
              {priceType
                ? `${priceType?.substring(0, 3)} ${priceType?.substring(3)?.toLowerCase()}`
                : 'per child'}{' '}
              {startDate && endDate && `(${moment(endDate)?.diff(moment(startDate), 'days')} days)`}{' '}
            </li>
          </ul>
        )}

        <ul className={css.toul}>
          {trialOption ? (
            <li>
              Trial Option <AiOutlineCheckCircle />
            </li>
          ) : null}

          {depositOption ? (
            <li>
              Deposit Option <AiOutlineCheckCircle />
            </li>
          ) : null}
          {siblingDiscount ? (
            <li>
              Sibling Discount <AiOutlineCheckCircle />
            </li>
          ) : null}
          {wheelChair ? (
            <li>
              Wheelchair-Friendly <AiOutlineCheckCircle />
            </li>
          ) : null}
          {specialNeeds ? (
            <li>
              Additional Needs Friendly <AiOutlineCheckCircle />
            </li>
          ) : null}
        </ul>
      </div>
    );
    const backButtonMaybe = (
      <div className={css.bckdiv}>
        <button
          onClick={e => {
            history.goBack();
          }}
          className={css.bckbtn}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
          <span>Back to Browse Activities</span>
        </button>
      </div>
    );
    const fallbackCopyTextToClipboard = text => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Link copied to clipboard!');
      } catch (err) {
        console.error('Error copying text: ', err);
        alert('Failed to copy link to clipboard. Please try again.');
      }
      document.body.removeChild(textArea);
    };
    const handleCustomShare = platform => {
      let shareUrl = '';

      switch (platform) {
        case 'email':
          shareUrl = `mailto:?subject=Check this out&body=${urlShr}`;
          break;
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlShr}`;
          break;
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            urlShr
          )}`;
          break;
        case 'snapchat':
          shareUrl = `https://www.snapchat.com/scan?attachmentUrl=${urlShr}`;
          break;
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?url=${urlShr}`;
          break;
        case 'whatsapp':
          shareUrl = `https://api.whatsapp.com/send?text=${urlShr}`;
          break;
        case 'copy':
          this.setState({ copiedNotification: true });
          if (
            typeof window !== 'undefined' &&
            typeof window.navigator !== 'undefined' &&
            typeof window.navigator.clipboard !== 'undefined' &&
            typeof window.navigator.clipboard.writeText !== 'undefined'
          ) {
            window.navigator.clipboard
              .writeText(urlShr)
              .then(() => {
                setTimeout(() => this.setState({ copiedNotification: false }, 500));
              })
              .catch(err => fallbackCopyTextToClipboard(urlShr));
          } else {
            fallbackCopyTextToClipboard(urlShr);
          }
          this.setState({ copiedNotification: false });
          return;

        default:
          return;
      }
      // typeof window !== 'undefined' && window.open(shareUrl, '_blank');
    };
    const handleShare = async () => {
      if (typeof window.navigator.share !== 'undefined') {
        try {
          await navigator.share({
            title: title,
            text: listingString,
            url: urlShr,
          });
          console.log('Content shared successfully');
        } catch (error) {
          console.error('Error sharing:', error);
          if (viewport.width > 768) this.setState({ openShareModal: true });
        }
      } else {
        this.setState({ openShareModal: true });
      }
    };
    const galleryMaybe = (
      <div className={css.gallerydiv}>
        <ImageGallery
          items={images}
          infinite={true}
          // renderItem={renderItem}
          showPlayButton={false}
          renderCustomControls={() => {
            return (
              <div className={css.cstmctrl}>
                {spacesInfo}
                {isCurrentUserProvider ? null : this.state.setFavouriteLoading ? (
                  <AiOutlineHeart
                    className={`${css.favouriteLoading} ${css.favicon}`}
                    onClick={() => {}}
                  />
                ) : this.state.isFavourite ? (
                  <AiFillHeart
                    className={`${css.fav} ${css.favicon}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.setState(state => ({
                        ...state,
                        setFavouriteLoading: true,
                      }));
                      axios
                        .post(`${apiBaseUrl()}/api/toggle-favourites`, {
                          userId: currentUser.id.uuid,
                          listingId: currentListing.id.uuid,
                        })
                        .then(res => {
                          this.setState(state => ({
                            ...state,
                            isFavourite: res.data,
                            setFavouriteLoading: false,
                          }));
                        });
                    }}
                  />
                ) : (
                  <AiOutlineHeart
                    className={css.favicon}
                    onClick={e => {
                      e.stopPropagation();
                      this.setState(state => ({
                        ...state,
                        setFavouriteLoading: true,
                      }));
                      axios
                        .post(`${apiBaseUrl()}/api/toggle-favourites`, {
                          userId: currentUser.id.uuid,
                          listingId: currentListing.id.uuid,
                        })
                        .then(res => {
                          this.setState(state => ({
                            ...state,
                            isFavourite: res.data,
                            setFavouriteLoading: false,
                          }));
                        });
                    }}
                  />
                )}
                <ShareButton
                  listing={currentListing}
                  className={css.shrBtn}
                  id="listing-page-top-button"
                  buttonText={
                    <>
                      <span>
                        <RiShareForwardLine />
                      </span>
                      <span>Share</span>
                    </>
                  }
                />
              </div>
            );
          }}
          renderRightNav={(onClick, disabled) => (
            <button
              onClick={onClick}
              disabled={disabled}
              className="image-gallery-icon image-gallery-right-nav"
            >
              <MdOutlineKeyboardArrowRight className="image-gallery-svg" />
            </button>
          )}
          renderLeftNav={(onClick, disabled) => (
            <button
              onClick={onClick}
              disabled={disabled}
              className="image-gallery-icon image-gallery-left-nav"
            >
              <MdOutlineKeyboardArrowLeft className="image-gallery-svg" />
            </button>
          )}
          additionalClass={css.wrapperClassName}
          thumbnailPosition={width <= 767 ? 'bottom' : 'right'}
          // thumbnailPosition="right"
          showFullscreenButton={false}
        />
      </div>
    );
    const activityInfosMaybe = (
      <div className={`${css.abtact} ${css.section}`}>
        <div className={css.title}>About this Activity:</div>
        <div className={css.lsttxt}>
          <ReadmoreButton
            description={description}
            collapsedLength={220}
            pclassName={css.lsttxt}
            readmbtn={css.readmbtn}
          />
        </div>
      </div>
    );
    const providerCategories = currentListing?.author?.attributes?.profile?.publicData?.categories;
    const categoryOptions = findOptionsForSelectFilter('categories', filterConfig);
    const spacesInfo = (
      <div
        className={classNames(css.spdv, {
          // [css.dispn]: isMobileModalOpen,
        })}
      >
        {publicData?.categories[0] === 'afterSchoolClub' ||
        publicData?.categories[0] === 'birthdayParty' ||
        publicData?.categories[0] === 'breakfastClub' ||
        publicData?.categories[0] === 'club' ||
        publicData?.categories[0] === 'events' ||
        publicData?.categories[0] === 'inSchoolProgrammes' ||
        publicData?.categories[0] === 'placesToVisit' ||
        publicData?.categories[0] === 'schoolTours' ? (
          publicData?.bookingType === 'offPlatform' ? (
            <div className={css.spcspn}>
              <span>Off-Platform</span>
            </div>
          ) : (
            <div className={css.spcspn}>
              <span>Contact to Book</span>
            </div>
          )
        ) : null}
        {publicData?.categories[0] === 'camps' ||
        publicData?.categories[0] === 'classes' ||
        publicData?.categories[0] === 'workshops' ? (
          publicData?.bookingType === 'offPlatform' ? (
            <div className={css.spcspn}>
              <span>Off-Platform</span>
            </div>
          ) : currentListing?.currentStock?.attributes?.quantity ? (
            <div className={css.spcspn}>
              <span>Spaces: </span>
              {currentListing?.currentStock?.attributes?.quantity > 25
                ? '25+'
                : currentListing?.currentStock?.attributes?.quantity}
            </div>
          ) : (
            <div className={css.spcspn}>
              <span>+ Waiting List</span>
            </div>
          )
        ) : null}
      </div>
    );
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            {topbar}
            <UserNav selectedPageName="ListingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              {backButtonMaybe}
              {galleryMaybe}
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  {listingInfos}
                  {activityInfosMaybe}

                  <div className={`${css.listSection} ${css.section}`}>
                    <div className={css.title}>What to Bring:</div>
                    <div className={css.lsttxt}>
                      <ReadmoreButton
                        description={whatToBring}
                        collapsedLength={220}
                        pclassName={css.lsttxt}
                        readmbtn={css.readmbtn}
                      />
                    </div>
                  </div>

                  {additionalInfo ? (
                    <div className={`${css.listSection} ${css.section}`}>
                      <div className={css.title}>Additional Info:</div>
                      <div className={css.lsttxt}>
                        <ReadmoreButton
                          description={additionalInfo}
                          collapsedLength={220}
                          pclassName={css.lsttxt}
                          readmbtn={css.readmbtn}
                        />
                      </div>
                    </div>
                  ) : null}

                  {categories[0] === 'afterSchoolClub' || categories[0] === 'breakfastClub' ? (
                    <div
                      className={classNames(css.prcavasec, { [css.wainf]: additionalPricingInfo })}
                    >
                      <span>Pricing & Availability</span>
                      <div>
                        <div className={css.secdiv}>
                          <span>Per Child: </span>
                          {formattedPrice}
                        </div>
                        <div className={css.secdiv}>
                          <span>Sibling Discount: </span>
                          {siblingDiscount
                            ? `€${price.amount / 100}, €${childOne?.amount}, €${childTwo?.amount}`
                            : formattedPrice}
                        </div>
                      </div>
                      {additionalPricingInfo ? (
                        <p className={css.additionalPricingInfo}>{additionalPricingInfo}</p>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className={classNames(css.impdet, {
                        [css.impdetcls]: categories[0] === 'classes',
                      })}
                    >
                      <span>Important Details</span>
                      {categories[0] === 'events' ? (
                        <div>
                          {publicData?.perAdult && (
                            <div>
                              <span>Per Adult: </span>
                              <span>
                                {publicData?.perAdult?.amount === 0
                                  ? 'Free'
                                  : `€${publicData?.perAdult?.amount}`}
                              </span>
                            </div>
                          )}
                          {publicData?.perFamily && (
                            <div>
                              <span>Per Family: </span>
                              <span>
                                {publicData?.perFamily?.amount === 0
                                  ? 'Free'
                                  : `€${publicData?.perFamily?.amount}`}
                              </span>
                            </div>
                          )}
                          {publicData?.perStudent && (
                            <div>
                              <span>Per Students: </span>
                              <span>
                                {publicData?.perStudent?.amount === 0
                                  ? 'Free'
                                  : `€${publicData?.perStudent?.amount}`}
                              </span>
                            </div>
                          )}
                          {publicData?.perOAP && (
                            <div>
                              <span>Per OAP: </span>
                              <span>
                                {publicData?.perOAP?.amount === 0
                                  ? 'Free'
                                  : `€${publicData?.perOAP?.amount}`}
                              </span>
                            </div>
                          )}
                          {publicData?.additionalPricingInfo ? (
                            <div>
                              <span>Pricing info: </span>
                              <span>{publicData?.additionalPricingInfo}</span>
                            </div>
                          ) : null}
                        </div>
                      ) : categories[0] === 'placesToVisit' ? (
                        <div>
                          {publicData?.additionalPricingInfo && (
                            <div>
                              <span>Pricing Info: </span>
                              <span>{publicData?.additionalPricingInfo}</span>
                            </div>
                          )}
                          {publicData?.description && (
                            <div>
                              <span>Opening Times: </span>
                              <span>{publicData?.description}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {categories[0] === 'club' ||
                          categories[0] === 'inSchoolProgrammes' ||
                          categories[0] === 'schoolTours' ||
                          categories[0] === 'birthdayParty' ? null : (
                            <div>
                              <span>Sibling Discount:</span>
                              <span>
                                {siblingDiscount
                                  ? `€${price.amount / 100}, €${childOne?.amount}, €${
                                      childTwo?.amount
                                    }`
                                  : 'Not Applicable'}
                              </span>
                            </div>
                          )}
                          {categories[0] !== 'club' && (
                            <div>
                              <span>Security Deposit:</span>
                              <span>
                                {depositOptionAmount
                                  ? typeof depositOptionAmount === 'number'
                                    ? `€${depositOptionAmount}`
                                    : `€${depositOptionAmount?.amount}`
                                  : 'Not Applicable'}
                              </span>
                            </div>
                          )}
                          {categories[0] === 'classes' && trialOption ? (
                            <div>
                              <span>Trial:</span>
                              <span>
                                {trialFee
                                  ? typeof trialFee === 'number'
                                    ? `€${trialFee === 0 ? 'FREE' : trialFee}`
                                    : `€${trialFee.amount === 0 ? 'FREE' : trialFee?.amount}`
                                  : 'Not Applicable'}
                                {trialMsg ? <span> ({trialMsg})</span> : null}
                              </span>
                            </div>
                          ) : null}
                          {publicData?.earlyDropOff && publicData?.earlyDropOffTime ? (
                            <div>
                              <span>Early Drop Off: </span>
                              <span>
                                {publicData?.earlyDropOffTime?.hour >= 10
                                  ? publicData?.earlyDropOffTime?.hour
                                  : `0${publicData?.earlyDropOffTime?.hour}`}
                                :
                                {publicData?.earlyDropOffTime?.minute >= 10
                                  ? publicData?.earlyDropOffTime?.minute
                                  : `0${publicData?.earlyDropOffTime?.minute}`}
                                , €{publicData?.latePickup?.amount} daily
                              </span>
                            </div>
                          ) : null}
                          {publicData?.latePickup && publicData?.latePickupTime ? (
                            <div>
                              <span>Late Pick Up: </span>
                              <span>
                                {publicData?.latePickupTime?.hour >= 10
                                  ? publicData?.latePickupTime?.hour
                                  : `0${publicData?.latePickupTime?.hour}`}
                                :
                                {publicData?.latePickupTime?.minute >= 10
                                  ? publicData?.latePickupTime?.minute
                                  : `0${publicData?.latePickupTime?.minute}`}
                                , €{publicData?.latePickup?.amount} daily
                              </span>
                            </div>
                          ) : null}
                          {publicData?.additionalPricingInfo ? (
                            <div>
                              <span>Pricing info: </span>
                              <span>{publicData?.additionalPricingInfo}</span>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}
                  <div className={css.providerOffer}>
                    <span>What does this Provider offer</span>
                    <div className={css.ctcnt}>
                      {providerCategories ? (
                        providerCategories.map(c => (
                          <div key={c}>
                            <label>{categoryOptions.find(ct => ct.value === c).label}</label>
                          </div>
                        ))
                      ) : (
                        <div>
                          <label>
                            {categoryOptions.find(ct => ct.value === categories[0]).label}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  {openCloseInfo}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  // onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  width={width}
                  publicData={publicData}
                  currentAuthor={currentAuthor}
                  checkoutLoading={this.state.checkoutLoading}
                  onUpdateProfile={onUpdateProfile}
                />
              </div>
              {/* {importantDetails} */}
              {lat && lng && (
                <div className={`${css.section} ${css.listingmap}`}>
                  <Map
                    reusableContainerClassName={css.map}
                    center={origin}
                    zoom={16}
                    bounds={bounds}
                    mapRootClassName={css.mapRootClassName}
                    className={css.mapbox}
                  />
                </div>
              )}
              <div className={`${css.contentContainer} ${css.riverowlist}`}>
                <div className={`${css.mainContent} ${css.mc0}`}>
                  <div className={`${css.section} ${css.abtprvm}`}>
                    <div className={css.title}>About the Provider</div>
                    <div className={css.listingrevaut}>
                      <div className={css.avtrdv}>
                        <AvatarLarge user={currentAuthor} disableProfileLink />
                      </div>
                      <div className={css.avtrotr}>
                        <span>
                          <p className={css.autpvdname}>
                            {currentAuthor?.attributes?.profile?.publicData?.bname} {/*  */}
                            {currentAuthor?.attributes?.profile?.publicData?.avgRating > 0 ? (
                              <>
                                <StarRating
                                  className={css.posstrcont}
                                  rating={Number(
                                    currentAuthor?.attributes?.profile?.publicData?.avgRating || 0
                                  )}
                                />{' '}
                                <span className={css.rtng}>
                                  ({currentAuthor?.attributes?.profile?.publicData?.avgRating})
                                </span>
                              </>
                            ) : (
                              <span className={css.ratestar}>
                                <FaStar />
                                (0) new
                              </span>
                            )}
                          </p>
                          <p>Tel: {currentAuthor?.attributes?.profile?.publicData?.phoneNumber}</p>
                          <p className={css.headqin}>
                            Headquartered in{' '}
                            {
                              countyOption.find(
                                c =>
                                  c.value === currentAuthor?.attributes?.profile?.publicData?.county
                              )?.label
                            }
                          </p>
                        </span>
                        <p className={css.lsttxt}>
                          {currentAuthor?.attributes?.profile?.publicData?.tagline}
                        </p>
                      </div>
                    </div>

                    {currentAuthor?.attributes?.profile?.publicData?.isProfilePublished ? (
                      <NamedLink
                        name={'ProfilePage'}
                        params={{ id: currentAuthor.id.uuid }}
                        className={`${css.readmbtn} ${css.profmore}`}
                      >
                        Read more
                      </NamedLink>
                    ) : (
                      <p>Provider yet to publish profile</p>
                    )}
                  </div>
                </div>
                <div className={css.reviewsecpanel}>
                  <h3>Reviews </h3>
                  {reviews?.length > 0 ? (
                    <div>
                      {reviews?.map((rev, idx) => {
                        if (idx >= 2) return undefined;
                        const { rating, content } = rev.attributes;
                        const formattedContent =
                          content?.length > 100 ? content.slice(0, 100) + '...' : content;
                        const revAuthor = rev.author?.attributes?.profile?.displayName;
                        const revCounty =
                          rev.author?.attributes?.profile?.publicData?.county?.label;
                        return (
                          <div className={css.revRating}>
                            <p>
                              <span>
                                {revAuthor} - {revCounty}
                              </span>
                            </p>
                            <span>
                              {' '}
                              Rating:
                              {[...Array(5).keys()].map((s, i) =>
                                i <= rating - 1 ? <AiFillStar /> : <AiOutlineStar />
                              )}
                            </span>
                            <p>{formattedContent}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <p>Tried an activity from this new activity provider?</p>
                      <p>
                        Your feedback helps other parents and teachers make informed choices and
                        supports the provider in offering the best activities.
                      </p>
                      <p>Don’t forget to “Leave a Review” within 7 days of the activity ending!</p>
                      <a
                        href={pathByRouteName('InboxPage', routeConfiguration(), {
                          tab: 'orders',
                        })}
                        className={css.createListingButton}
                      >
                        Leave a Review{' '}
                      </a>
                    </>
                  )}
                  {reviews?.length > 2 ? (
                    <span
                      onClick={() =>
                        history.push(
                          createResourceLocatorString(
                            'ProfilePage',
                            routeConfiguration(),
                            {
                              id: currentAuthor.id.uuid,
                            },
                            {},
                            '#reviews-section'
                          )
                        )
                      }
                      className={css.readmbtn}
                    >
                      More reviews
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className={css.providerListings}>
                <Wave colName={'#fff'} />
                <h1 className={css.title}>More from this Provider</h1>
                {paginationLinks}
                <div className={css.section}>
                  {authorListings?.map(l => (
                    <div className={css.profilecol} key={l.id.uuid}>
                      <ProviderListingCard key={l.id.uuid} listing={l} />
                    </div>
                  ))}
                </div>
              </div>

              <div className={css.fscsection}>
                <Wave />

                <div className={`${css.cpycnt} ${css.contentContainer}`}>
                  <div>{backButtonMaybe}</div>

                  <div className={css.shrsec}>
                    <span>Want to Share this Activity?</span>
                    <span>
                      Let other parents know about this activity<span>...</span>
                    </span>
                    {/* <span id="listingurl">{}</span> */}
                    <ShareButton
                      id="listing-page-bottom-button"
                      listing={currentListing}
                      buttonText="Share"
                    />
                  </div>
                  <div className={css.totop}>
                    <span
                      onClick={() => {
                        if (window !== undefined) {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <BiUpArrowAlt />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal> */}
          </LayoutWrapperMain>
          <LayoutWrapperFooter className={css.ftrwrp}>
            <Wave colName={'#fff'} />
            <SectionFooterTop className={css.footerTop} />
            <Wave colName={'#002435'} />
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    authorListingsIds,
    authorListingsPagination,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const authorListings = authorListingsIds && getListingsById(state, authorListingsIds);
  const getListing = id => {
    const { entities } = state.marketplaceData;
    if (!entities.hasOwnProperty('listing')) return null;
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    authorListings,
    authorListingsPagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  // onFetchTimeSlots: (listingId, start, end, timeZone) =>
  //   dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onInitiateZeroOrder: orderParams => dispatch(initiateZeroOrder(orderParams)),
  onUpdateProfile: data => dispatch(updateProfileWithoutSuccessTrigger(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
